import { zodResolver } from "@hookform/resolvers/zod";
import { XIcon } from "lucide-react";
import { useForm } from "react-hook-form";

import { CompaniesCombobox } from "@/components/companies-combobox";
import { DocumentInput } from "@/components/document-input";
import { ImageUploadContainer } from "@/components/image-upload";
import {
  NavigationSidebar,
  NavigationSidebarHeader,
  NavigationSidebarItem,
} from "@/components/navigation-sidebar";
import {
  PrettyInput,
  PrettyInputControl,
  PrettyInputFooter,
  PrettyInputFormMessage,
  PrettyInputItem,
  PrettyInputLabel,
} from "@/components/pretty-input";
import { StatesSelect } from "@/components/states-select";
import {
  SwitchBox,
  SwitchBoxDescription,
  SwitchBoxTitle,
} from "@/components/switch-box";
import { Button } from "@/components/ui/button";
import { Form, FormMessage } from "@/components/ui/form";
import { Input, PasswordInput } from "@/components/ui/input";
import { PhoneInput } from "@/components/ui/phone-input";
import {
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
  TooltipContent,
} from "@/components/ui/tooltip";
import { NeighboorhodCombobox } from "@/pages/deliveries/components/neighborhood-combobox";

import { SelectUserRole } from "../../components/select-user-role";
import { useGetUserSuspenseQuery } from "../hooks/queries/use-get-user-suspense-query";
import { updateUserFormSchema, UpdateUserSchema } from "../schema";
import { SaveAddressButton } from "./save-address-button";
import { SaveButton } from "./save-button";
import { SectionTitle } from "./section-title";
import { ZipCodeAutocomplete } from "./zip-code-autocomplete";

export type UpdateUserProps = {
  userId: string;
};

export function UpdateUser({ userId }: UpdateUserProps) {
  const { data: user } = useGetUserSuspenseQuery(userId);

  return (
    <UpdateUserForm
      id={user.id}
      name={user.name}
      email={user.contact.email}
      document={user.documentation.document}
      companyId={user.company?.id || null}
      roleId={user.roles[0]?.id.toString() || ""}
      isBlocked={user.isBlocked}
      cellphone={user.contact.cellphone || ""}
      telephone={user.contact.telephone || ""}
      zipCode={user.address?.zipCode || ""}
      street={user.address?.street || ""}
      number={user.address?.number || ""}
      neighborhoodId={user.address?.neighborhoodId || ""}
      city={user.address?.city || ""}
      state={(user.address?.state as any) || "RN"}
      avatar={user.avatar || null}
      cnhPicture={user.documentation.cnhPicture || null}
      crlvPicture={user.documentation.crlvPicture || null}
    />
  );
}

export type UpdateUserFormProps = {
  id: string;
  name: string;
  email: string;
  document: string;
  companyId: string | null;
  roleId: string;
  isBlocked: boolean;
  cellphone: string;
  telephone: string;
  zipCode: string;
  street: string;
  number: string;
  neighborhoodId: string;
  city: string;
  state: "RN";
  avatar: string | null;
  cnhPicture: string | null;
  crlvPicture: string | null;
};

export function UpdateUserForm(defaultValues: UpdateUserFormProps) {
  const form = useForm<UpdateUserSchema>({
    resolver: zodResolver(updateUserFormSchema),
    defaultValues,
  });
  const userId = defaultValues.id;

  return (
    <div className="grid lg:grid-cols-[25fr_75fr] gap-8">
      <NavigationSidebar className="lg:sticky top-4">
        <NavigationSidebarHeader>Navegar</NavigationSidebarHeader>

        <NavigationSidebarItem target="#identification">
          Identificação
        </NavigationSidebarItem>

        <NavigationSidebarItem target="#contact">Contato</NavigationSidebarItem>

        <NavigationSidebarItem target="#documentation">
          Documentação
        </NavigationSidebarItem>

        <NavigationSidebarItem target="#address">
          Endereço
        </NavigationSidebarItem>

        <NavigationSidebarItem target="#others">Outros</NavigationSidebarItem>
      </NavigationSidebar>

      <div className="flex flex-col gap-4">
        <Form {...form}>
          <section id="identification" className="flex flex-col gap-4">
            <SectionTitle>Identificação</SectionTitle>

            <PrettyInput
              control={form.control}
              name="name"
              render={({ field }) => (
                <PrettyInputItem>
                  <PrettyInputLabel>Nome completo</PrettyInputLabel>

                  <PrettyInputControl>
                    <Input {...field} />
                  </PrettyInputControl>

                  <FormMessage />

                  <PrettyInputFooter>
                    <div>O nome completo do usuário</div>

                    <SaveButton userId={userId} fieldPath="name" />
                  </PrettyInputFooter>
                </PrettyInputItem>
              )}
            />

            <PrettyInput
              control={form.control}
              name="roleId"
              render={({ field }) => (
                <PrettyInputItem>
                  <PrettyInputLabel>Cargo</PrettyInputLabel>

                  <PrettyInputControl>
                    <SelectUserRole
                      value={field.value}
                      onChange={field.onChange}
                    />
                  </PrettyInputControl>

                  <PrettyInputFormMessage />

                  <PrettyInputFooter>
                    <div>
                      Selecione a empresa a qual o usuário está vinculado.
                    </div>

                    <SaveButton userId={userId} fieldPath="roleId" />
                  </PrettyInputFooter>
                </PrettyInputItem>
              )}
            />

            <PrettyInput
              control={form.control}
              name="password"
              render={({ field }) => (
                <PrettyInputItem>
                  <PrettyInputLabel>Senha</PrettyInputLabel>

                  <PrettyInputControl>
                    <PasswordInput type="password" {...field} />
                  </PrettyInputControl>

                  <PrettyInputFormMessage />

                  <PrettyInputFooter>
                    <div>
                      Escolha uma senha segura, com no mínimo 6 caracteres.
                    </div>
                    <SaveButton userId={userId} fieldPath="password" />
                  </PrettyInputFooter>
                </PrettyInputItem>
              )}
            />

            <PrettyInput
              control={form.control}
              name="companyId"
              render={({ field }) => (
                <PrettyInputItem>
                  <PrettyInputLabel>Empresa</PrettyInputLabel>

                  <PrettyInputControl>
                    <div className="flex justify-between gap-2">
                      <TooltipProvider delayDuration={0}>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              type="button"
                              variant="outline"
                              onClick={() => field.onChange(null)}
                            >
                              <XIcon className="w-3 h-3" />
                            </Button>
                          </TooltipTrigger>

                          <TooltipContent>Desvincular empresa</TooltipContent>
                        </Tooltip>
                      </TooltipProvider>

                      <CompaniesCombobox
                        value={field.value || ""}
                        onChange={field.onChange}
                      />
                    </div>
                  </PrettyInputControl>

                  <PrettyInputFormMessage />

                  <PrettyInputFooter>
                    <div>
                      Selecione a empresa a qual o usuário está vinculado.
                    </div>
                    <SaveButton userId={userId} fieldPath="companyId" />
                  </PrettyInputFooter>
                </PrettyInputItem>
              )}
            />
          </section>

          <section id="contact" className="flex flex-col gap-4">
            <SectionTitle>Contato</SectionTitle>

            <PrettyInput
              control={form.control}
              name="email"
              render={({ field }) => (
                <PrettyInputItem>
                  <PrettyInputLabel>E-mail</PrettyInputLabel>

                  <PrettyInputControl>
                    <Input type="email" {...field} />
                  </PrettyInputControl>

                  <PrettyInputFormMessage />

                  <PrettyInputFooter>
                    <div>Insira um endereço de e-mail válido</div>

                    <SaveButton userId={userId} fieldPath="contact.email" />
                  </PrettyInputFooter>
                </PrettyInputItem>
              )}
            />

            <PrettyInput
              control={form.control}
              name="cellphone"
              render={({ field }) => (
                <PrettyInputItem>
                  <PrettyInputLabel>Celular</PrettyInputLabel>

                  <PrettyInputControl>
                    <PhoneInput
                      defaultValue={field.value || ""}
                      onInput={async (event) =>
                        field.onChange(event.currentTarget.value)
                      }
                    />
                  </PrettyInputControl>

                  <PrettyInputFormMessage />

                  <PrettyInputFooter>
                    <div>Insira um número de celular válido para contato</div>

                    <SaveButton userId={userId} fieldPath="contact.phone" />
                  </PrettyInputFooter>
                </PrettyInputItem>
              )}
            />

            <PrettyInput
              control={form.control}
              name="telephone"
              render={({ field }) => (
                <PrettyInputItem>
                  <PrettyInputLabel>
                    Telefone{" "}
                    <span className="text-xs text-muted-foreground">
                      (opcional)
                    </span>
                  </PrettyInputLabel>

                  <PrettyInputControl>
                    <PhoneInput
                      defaultValue={field.value || ""}
                      onInput={async (event) =>
                        field.onChange(event.currentTarget.value)
                      }
                    />
                  </PrettyInputControl>

                  <PrettyInputFormMessage />

                  <PrettyInputFooter>
                    <div>Insira um número de telefone válido para contato</div>

                    <SaveButton userId={userId} fieldPath="contact.telephone" />
                  </PrettyInputFooter>
                </PrettyInputItem>
              )}
            />
          </section>

          <section id="documentation" className="flex flex-col gap-4">
            <SectionTitle>Documentação</SectionTitle>

            <PrettyInput
              control={form.control}
              name="avatar"
              render={({ field }) => (
                <PrettyInputItem>
                  <PrettyInputLabel>Foto do rosto</PrettyInputLabel>

                  <PrettyInputControl>
                    <ImageUploadContainer
                      name="avatar"
                      fileType="deliveryman"
                      value={field.value}
                      onChange={field.onChange}
                    />
                  </PrettyInputControl>

                  <PrettyInputFormMessage />

                  <PrettyInputFooter>
                    <div>
                      Insira uma foto do rosto do usuário, para identificação.
                    </div>

                    <SaveButton userId={userId} fieldPath="avatar" />
                  </PrettyInputFooter>
                </PrettyInputItem>
              )}
            />

            <PrettyInput
              control={form.control}
              name="cnhPicture"
              render={({ field }) => (
                <PrettyInputItem>
                  <PrettyInputLabel>Foto da CNH</PrettyInputLabel>

                  <PrettyInputControl>
                    <ImageUploadContainer
                      name="cnhPicture"
                      fileType="cnh"
                      value={field.value}
                      onChange={field.onChange}
                    />
                  </PrettyInputControl>

                  <PrettyInputFormMessage />

                  <PrettyInputFooter>
                    <div>Insira uma foto da CNH do usuário.</div>

                    <SaveButton
                      userId={userId}
                      fieldPath="documentation.cnhPicture"
                    />
                  </PrettyInputFooter>
                </PrettyInputItem>
              )}
            />

            <PrettyInput
              control={form.control}
              name="crlvPicture"
              render={({ field }) => (
                <PrettyInputItem>
                  <PrettyInputLabel>Foto do CRVL</PrettyInputLabel>

                  <PrettyInputControl>
                    <ImageUploadContainer
                      name="crlvPicture"
                      fileType="crlv"
                      value={field.value}
                      onChange={field.onChange}
                    />
                  </PrettyInputControl>

                  <PrettyInputFormMessage />

                  <PrettyInputFooter>
                    <div>Insira uma foto do CRVL do usuário.</div>

                    <SaveButton
                      userId={userId}
                      fieldPath="documentation.crlvPicture"
                    />
                  </PrettyInputFooter>
                </PrettyInputItem>
              )}
            />

            <PrettyInput
              control={form.control}
              name="document"
              render={({ field }) => (
                <PrettyInputItem>
                  <PrettyInputLabel>Documento (CPF ou CNPJ)</PrettyInputLabel>

                  <PrettyInputControl>
                    <DocumentInput
                      cpfLabel="CPF"
                      cnpjLabel="CNPJ"
                      value={field.value}
                      onChange={field.onChange}
                    />
                  </PrettyInputControl>

                  <PrettyInputFormMessage />

                  <PrettyInputFooter>
                    <div>
                      Documento do usuário, caso seja pessoa física, insira o
                      CPF, caso seja pessoa jurídica, insira o CNPJ.
                    </div>

                    <SaveButton
                      userId={userId}
                      fieldPath="documentation.document"
                    />
                  </PrettyInputFooter>
                </PrettyInputItem>
              )}
            />
          </section>

          <section id="address" className="flex flex-col gap-4">
            <SectionTitle>
              Endereço
              <SaveAddressButton userId={userId} />
            </SectionTitle>

            <ZipCodeAutocomplete userId={userId} />

            <PrettyInput
              control={form.control}
              name="street"
              render={({ field }) => (
                <PrettyInputItem>
                  <PrettyInputLabel>Rua</PrettyInputLabel>

                  <PrettyInputControl>
                    <Input {...field} />
                  </PrettyInputControl>

                  <PrettyInputFormMessage />

                  <PrettyInputFooter>
                    <div>Insira a rua do endereço do usuário.</div>

                    <SaveButton userId={userId} fieldPath="address.street" />
                  </PrettyInputFooter>
                </PrettyInputItem>
              )}
            />

            <PrettyInput
              control={form.control}
              name="number"
              render={({ field }) => (
                <PrettyInputItem>
                  <PrettyInputLabel>Número</PrettyInputLabel>

                  <PrettyInputControl>
                    <Input {...field} />
                  </PrettyInputControl>

                  <PrettyInputFormMessage />

                  <PrettyInputFooter>
                    <div>Insira o número do endereço do usuário.</div>

                    <SaveButton userId={userId} fieldPath="address.number" />
                  </PrettyInputFooter>
                </PrettyInputItem>
              )}
            />

            <PrettyInput
              control={form.control}
              name="neighborhoodId"
              render={({ field }) => (
                <PrettyInputItem>
                  <PrettyInputLabel>Bairro</PrettyInputLabel>

                  <PrettyInputControl>
                    <NeighboorhodCombobox
                      value={field.value}
                      onChange={field.onChange}
                    />
                  </PrettyInputControl>

                  <PrettyInputFormMessage />

                  <PrettyInputFooter>
                    <div>Selecione o bairro do endereço do usuário.</div>

                    <SaveButton
                      userId={userId}
                      fieldPath="address.neighborhoodId"
                    />
                  </PrettyInputFooter>
                </PrettyInputItem>
              )}
            />

            <PrettyInput
              control={form.control}
              name="city"
              render={({ field }) => (
                <PrettyInputItem>
                  <PrettyInputLabel>Cidade</PrettyInputLabel>

                  <PrettyInputControl>
                    <Input {...field} />
                  </PrettyInputControl>

                  <PrettyInputFormMessage />

                  <PrettyInputFooter>
                    <div>Insira a cidade do endereço do usuário.</div>

                    <SaveButton userId={userId} fieldPath="address.city" />
                  </PrettyInputFooter>
                </PrettyInputItem>
              )}
            />

            <PrettyInput
              control={form.control}
              name="state"
              render={({ field }) => (
                <PrettyInputItem>
                  <PrettyInputLabel>Estado</PrettyInputLabel>

                  <PrettyInputControl>
                    <StatesSelect
                      value={field.value}
                      onChange={field.onChange}
                    />
                  </PrettyInputControl>

                  <PrettyInputFormMessage />

                  <PrettyInputFooter>
                    <div>Selecione o estado do endereço do usuário.</div>

                    <SaveButton userId={userId} fieldPath="address.state" />
                  </PrettyInputFooter>
                </PrettyInputItem>
              )}
            />
          </section>

          <section id="others" className="flex flex-col gap-4">
            <SectionTitle>Outros</SectionTitle>

            <PrettyInput
              control={form.control}
              name="isBlocked"
              render={({ field }) => (
                <PrettyInputItem>
                  <PrettyInputLabel>
                    Bloquear usuário
                    <SwitchBox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                      className="mt-2"
                    >
                      <SwitchBoxTitle>Bloquear usuário</SwitchBoxTitle>

                      <SwitchBoxDescription>
                        Clique para bloquear o usuário
                      </SwitchBoxDescription>
                    </SwitchBox>
                  </PrettyInputLabel>

                  <PrettyInputFormMessage />

                  <PrettyInputFooter>
                    <div>
                      Ao bloquear o usuário, ele não poderá acessar o sistema.
                    </div>

                    <SaveButton userId={userId} fieldPath="isBlocked" />
                  </PrettyInputFooter>
                </PrettyInputItem>
              )}
            />
          </section>
        </Form>
      </div>
    </div>
  );
}
