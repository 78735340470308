import { useSuspenseQuery } from "@tanstack/react-query";

import api from "@/services/api";

export type FileType = "deliveryman" | "cnh" | "crlv";

async function getSignedUrl(fileType: FileType) {
  const { data } = await api.get<{
    url: string;
  }>(`/file/${fileType}/uploadUrl`);

  return data.url;
}

export const USE_SIGNED_URL_QUERY_KEY = "useSignedUrl";

export function useSignedUrlQuery(fileType: FileType) {
  return useSuspenseQuery({
    queryKey: [USE_SIGNED_URL_QUERY_KEY, fileType],
    queryFn: () => getSignedUrl(fileType),
    staleTime: 0,
  });
}
