import { ChangeEvent, useRef } from "react";

const CPF_MASK = "___.___.___-__";
const CNPJ_MASK = "__.___.___/____-__";

type DocumentInputProps = {
  value: string;
  onChange: (value: string) => void;
  cpfLabel: string;
} & {
  value: string;
  onChange: (value: string) => void;
  cnpjLabel: string;
};

export function DocumentInput({ value, onChange }: DocumentInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  const formatCpf = (value: string) => {
    const formattedCpf = value
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})$/, "$1-$2");

    if (formattedCpf.length <= 14) {
      return formattedCpf + CPF_MASK.substring(formattedCpf.length);
    }

    return formattedCpf;
  };

  const formatCnpj = (value: string) => {
    const formattedCnpj = value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,4})/, "$1/$2")
      .replace(/(\d{4})(\d{1,2})$/, "$1-$2");

    if (formattedCnpj.length <= 18) {
      return formattedCnpj + CNPJ_MASK.substring(formattedCnpj.length);
    }

    return formattedCnpj;
  };

  const formatDocument = (value: string) => {
    const numericValue = value.replace(/\D/g, "");

    if (numericValue.length > 11) {
      return formatCnpj(value);
    }

    return formatCpf(value);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.replace(/\D/g, "");

    if (inputValue.length > 14) {
      e.preventDefault();
      return false;
    }

    const formattedValue = formatDocument(inputValue);
    const lastDigitPosition = formattedValue
      .split("")
      .findLastIndex((char, index) => {
        const isNumeric = !isNaN(parseInt(char, 10));

        if (isNumeric) {
          console.log({ char, index });
          return true;
        }

        return false;
      });
    onChange(formattedValue);

    queueMicrotask(() => {
      if (inputRef.current) {
        inputRef.current.setSelectionRange(
          lastDigitPosition + 1,
          lastDigitPosition + 1,
        );
      }
    });
  };

  return (
    <input
      id="document"
      type="text"
      value={formatDocument(value)}
      onChange={handleChange}
      onClick={(e) => {
        e.preventDefault();
        const lastDigitPosition = e.currentTarget.value
          .split("")
          .findLastIndex((char) => !isNaN(parseInt(char, 10)));

        e.currentTarget.setSelectionRange(
          lastDigitPosition + 1,
          lastDigitPosition + 1,
        );
      }}
      ref={inputRef}
      className="flex h-9 w-full rounded-md border border-input bg-background px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
    />
  );
}
