"use client";

import { useMediaQuery } from "@mantine/hooks";
import { noop } from "@tanstack/react-table";
import { Check, ChevronsUpDown } from "lucide-react";
import { useMemo, useState } from "react";

import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import { Drawer, DrawerContent, DrawerTrigger } from "@/components/ui/drawer";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { ScrollArea } from "@/components/ui/scroll-area";
import { cn } from "@/utils";

import { useNeighborhoodsQuery } from "../hooks/use-neighborhoods";

export type NeighboorhodComboboxProps = {
  disabled?: boolean;
  value: string;
  onChange?: (value: string) => void;
};

export function NeighboorhodCombobox({
  value,
  onChange = noop,
  disabled = false,
}: NeighboorhodComboboxProps) {
  const [open, setOpen] = useState(false);
  const { isFetched, data: neighborhoods } = useNeighborhoodsQuery();
  const isDesktop = useMediaQuery("(min-width: 768px)");

  const neighborhoodName = useMemo(() => {
    if (!isFetched || !neighborhoods) {
      return "Selecione um bairro";
    }

    const neighborhood = neighborhoods.data.find(
      (neighborhood) => neighborhood.id === value,
    );

    return neighborhood ? neighborhood.name : "Selecione um bairro";
  }, [neighborhoods, value]);

  if (isDesktop) {
    return (
      <Popover modal open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            disabled={disabled}
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="w-full justify-between"
          >
            {neighborhoodName}

            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>

        <PopoverContent className="popover-content-width-same-as-its-trigger w-[200px] p-0">
          <NeighborhoodList
            setOpen={setOpen}
            selectedNeighborhoodId={value}
            setSelectNeighborhood={onChange}
          />
        </PopoverContent>
      </Popover>
    );
  }

  return (
    <Drawer open={open} onOpenChange={setOpen}>
      <DrawerTrigger asChild>
        <Button
          disabled={disabled}
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-full justify-between"
        >
          {neighborhoodName}
        </Button>
      </DrawerTrigger>

      <DrawerContent>
        <div className="mt-4 border-t">
          <NeighborhoodList
            setOpen={setOpen}
            selectedNeighborhoodId={value}
            setSelectNeighborhood={onChange}
          />
        </div>
      </DrawerContent>
    </Drawer>
  );
}

type NeighborhoodListProps = {
  setOpen: (value: boolean) => void;
  selectedNeighborhoodId: string;
  setSelectNeighborhood: (value: string) => void;
};

function NeighborhoodList({
  setOpen,
  selectedNeighborhoodId,
  setSelectNeighborhood,
}: NeighborhoodListProps) {
  const {
    isFetched,
    isLoading,
    isError,
    data: neighborhoods,
  } = useNeighborhoodsQuery();

  if (isLoading) {
    return (
      <Command>
        <CommandItem disabled>Carregando...</CommandItem>
      </Command>
    );
  }

  if (isError || !neighborhoods || !Array.isArray(neighborhoods.data)) {
    return (
      <Command>
        <CommandItem disabled>Erro ao carregar bairros</CommandItem>
      </Command>
    );
  }

  return (
    <Command
      filter={(value, search) => {
        const searchLower = search
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        const valueLower = value
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");

        if (valueLower.includes(searchLower)) return 1;
        return 0;
      }}
    >
      <CommandInput placeholder="Buscar por bairro..." />

      <CommandEmpty>Nenhum bairro encontrado</CommandEmpty>

      <CommandGroup>
        <ScrollArea className="w-full [&>[data-radix-scroll-area-viewport]]:max-h-[300px]">
          {neighborhoods.data.map((neighborhood) => {
            const isSelected = selectedNeighborhoodId === neighborhood.id;

            return (
              <CommandItem
                key={neighborhood.name}
                value={neighborhood.name}
                onSelect={() => {
                  setSelectNeighborhood(neighborhood.id);
                  setOpen(false);
                }}
              >
                {isSelected && <Check className={cn("mr-2 h-4 w-4")} />}

                {neighborhood.name}
              </CommandItem>
            );
          })}
        </ScrollArea>
      </CommandGroup>
    </Command>
  );
}
