export function getObjectProperties(
  obj: Record<string, any>,
  prefix = "",
): string[] {
  let properties: string[] = [];

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const propertyPath = prefix ? `${prefix}.${key}` : key;

      if (typeof obj[key] === "object" && obj[key] !== null) {
        properties = properties.concat(
          getObjectProperties(obj[key], propertyPath),
        );
      } else {
        properties.push(propertyPath);
      }
    }
  }

  return properties;
}

type NestedProperty<
  Property extends string,
  Value,
> = Property extends `${infer A}.${infer B}`
  ? { [K in A]: NestedProperty<B, Value> }
  : { [K in Property]: Value };

export function createNestedObject<Property extends string, Value>(
  path: Property,
  value: Value,
): NestedProperty<Property, Value> {
  const keys = path.split(".");

  return keys.reduceRight<NestedProperty<Property, Value>>((acc, key) => {
    return { [key]: acc } as any;
  }, value as any);
}
