export function getFirstFileOrThrow(files: File[]) {
  if (files.length === 0) {
    throw new Error("No files provided");
  }

  const [firstFile] = files;

  if (!firstFile) {
    throw new Error("No files provided");
  }

  return firstFile;
}
