import ky from "ky";
import { z } from "zod";

export const locationSchema = z.object({
  cep: z.string(),
  state: z.string(),
  city: z.string(),
  neighborhood: z.string(),
  street: z.string(),
  service: z.string(),
});

export async function getAddressByCep(cep: string) {
  const response = await ky
    .get(`https://brasilapi.com.br/api/cep/v1/${cep}`)
    .json();

  return locationSchema.parse(response);
}
