import { useEffect, useState } from "react";

import { cn } from "@/utils";

export type NavigationSidebarProps = {
  className?: string;
  children: React.ReactNode | React.ReactNode[];
};

export function NavigationSidebar({
  className,
  children,
}: NavigationSidebarProps) {
  return (
    <div
      className={cn(
        "flex flex-col bg-background border rounded-lg h-fit overflow-hidden",
        className,
      )}
    >
      {children}
    </div>
  );
}

export function NavigationSidebarHeader({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div className="p-2 font-bold text-sm bg-background/50">{children}</div>
  );
}

export function NavigationSidebarItem({
  children,
  target,
}: {
  children: React.ReactNode;
  target: string;
}) {
  const isActive = useActiveTarget() === target.slice(1);

  return (
    <a
      href={target}
      data-active={isActive}
      className="text-sm font-medium h-full p-2 border-t first:border-0 bg-transparent hover:bg-muted data-[active=true]:font-bold data-[active=true]:border-l-4 data-[active=true]:border-l-orange-500"
      onClick={(event) => {
        event.preventDefault();
        const element = document.querySelector(target);

        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }}
    >
      {children}
    </a>
  );
}

function useActiveTarget() {
  const [activeTarget, setActiveTarget] = useState<string | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveTarget(entry.target.id);
          }
        });
      },
      { threshold: 0.7 },
    );

    document.querySelectorAll("section[id]").forEach((section) => {
      observer.observe(section);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return activeTarget;
}
