import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

export type SelectUserRoleProps = {
  value: string;
  onChange: (value: string) => void;
};

export function SelectUserRole({ value, onChange }: SelectUserRoleProps) {
  return (
    <Select defaultValue={value} onValueChange={onChange}>
      <SelectTrigger>
        <SelectValue placeholder="Selecionar cargo" />
      </SelectTrigger>

      <SelectContent>
        <SelectItem value="1">Mestre</SelectItem>
        <SelectItem value="2">Dono de empresa</SelectItem>
        <SelectItem value="3">Funcionário da empresa</SelectItem>
        <SelectItem value="4">Entregador</SelectItem>
      </SelectContent>
    </Select>
  );
}
