import { useFormContext } from "react-hook-form";

import { Button } from "@/components/ui/button";
import { useFormField } from "@/components/ui/form";
import { createNestedObject } from "@/lib/object-utils";

import { updateUserMutation } from "../hooks/mutations/update-user-mutation";
import { UpdateUserSchema } from "../schema";

export type SaveButtonProps = {
  userId: string;
  fieldPath: string;
};

export function SaveButton({ userId, fieldPath }: SaveButtonProps) {
  const form = useFormContext<UpdateUserSchema>();
  const { mutate: updateUser } = updateUserMutation();
  const { name: fieldName } = useFormField();

  return (
    <>
      <Button
        type="button"
        size="sm"
        className="w-fit"
        onClick={async () => {
          const isValid = await form.trigger(
            fieldName as keyof UpdateUserSchema,
          );

          if (isValid) {
            const fieldValue = form.getValues(
              fieldName as keyof UpdateUserSchema,
            );
            const updateBody = createNestedObject(fieldPath, fieldValue);

            updateUser({
              params: { path: { id: userId } },
              body: updateBody,
            });
          }
        }}
      >
        Salvar
      </Button>
    </>
  );
}
