import { useMutation } from "@tanstack/react-query";

import { queryClient } from "@/app";

import { getPublicUrl } from "../../utils/get-public-url";
import {
  FileType,
  USE_SIGNED_URL_QUERY_KEY,
} from "../queries/use-signed-url-query";

export type SaveImageInput = {
  file: File;
  fileType: FileType;
  signedUrl: string;
};

export async function saveImage({ file, fileType, signedUrl }: SaveImageInput) {
  const request = await fetch(signedUrl, {
    method: "PUT",
    body: file,
    headers: {
      "Content-Type": file.type,
    },
  });

  if (!request.ok) {
    throw new Error("Failed to save image");
  }

  return await getPublicUrl({
    signedUrl,
    fileType,
  });
}

export function useSaveImageMutation() {
  return useMutation({
    mutationFn: saveImage,

    onMutate(data) {
      queryClient.refetchQueries({
        queryKey: [USE_SIGNED_URL_QUERY_KEY, data.fileType, data.signedUrl],
      });
    },
  });
}
