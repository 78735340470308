import { ComponentProps } from "react";
import { ControllerProps, FieldPath, FieldValues } from "react-hook-form";

import { cn } from "@/utils";

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/form";

export function PrettyInput<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ ...otherProps }: ControllerProps<TFieldValues, TName>) {
  return (
    <div className={cn("bg-background border rounded-lg")}>
      <FormField {...otherProps} />
    </div>
  );
}

type PrettyInputItem = ComponentProps<typeof FormItem>;

export function PrettyInputItem(props: PrettyInputItem) {
  return <FormItem {...props} />;
}

type PrettyInputLabel = ComponentProps<typeof FormLabel>;

export function PrettyInputLabel({
  className,
  ...otherProps
}: PrettyInputLabel) {
  return (
    <div className={cn("p-2 font-bold", className)}>
      <FormLabel {...otherProps} />
    </div>
  );
}

type PrettyInputControl = ComponentProps<typeof FormControl>;

export function PrettyInputControl({
  className,
  ...otherProps
}: PrettyInputControl) {
  return (
    <div className={cn("p-2 pt-0", className)}>
      <FormControl {...otherProps} />
    </div>
  );
}

export type PrettyInputFooterProps = ComponentProps<"div">;

export function PrettyInputFooter({
  className,
  ...otherProps
}: PrettyInputFooterProps) {
  return (
    <div
      className={cn(
        "flex md:flex-row flex-col gap-2 items-center justify-center md:justify-between p-2 border-t text-sm text-muted-foreground bg-muted/50",
        className,
      )}
      {...otherProps}
    />
  );
}

export type PrettyInputFormMessage = ComponentProps<typeof FormMessage>;

export function PrettyInputFormMessage({
  className,
  ...otherProps
}: PrettyInputFormMessage) {
  return <FormMessage {...otherProps} className={cn("px-2", className)} />;
}
