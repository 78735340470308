import { queryClient } from "@/app";
import { usersApi } from "@/lib/users-api";

export function useGetUserSuspenseQuery(userId: string) {
  return usersApi.useSuspenseQuery(
    "get",
    "/v1/users/{id}",
    {
      params: {
        path: { id: userId },
      },
    },
    {},
    queryClient,
  );
}
