import { useQuery } from "@tanstack/react-query";

import api from "@/services/api";

const GET_NEIGHBORHOODS_QUERY_KEY = "neighborhoods";

export interface NeighborhoodOutput {
  data: Neighborhood[];
  currentPage: number;
  totalPages: number;
}

export interface Neighborhood {
  id: string;
  name: string;
  city: string;
  state: string;
  regionID?: string;
  regionName?: string;
}

async function fetchNeighborhoods() {
  const { data } = await api.get<NeighborhoodOutput>(
    "/neighborhood?all=true&itemsPerPage=1000",
  );

  return data;
}

export function useNeighborhoodsQuery() {
  return useQuery({
    queryKey: [GET_NEIGHBORHOODS_QUERY_KEY],
    queryFn: fetchNeighborhoods,
  });
}
