import { useFormContext } from "react-hook-form";

import { Button } from "@/components/ui/button";

import { updateUserMutation } from "../hooks/mutations/update-user-mutation";
import { UpdateUserSchema } from "../schema";

export type SaveAddressButtonProps = {
  userId: string;
};

export function SaveAddressButton({ userId }: SaveAddressButtonProps) {
  const form = useFormContext<UpdateUserSchema>();
  const { mutate: updateUser } = updateUserMutation();

  return (
    <>
      <Button
        type="button"
        size="sm"
        className="w-fit"
        onClick={async () => {
          const isValid = await form.trigger([
            "zipCode",
            "state",
            "city",
            "neighborhoodId",
            "street",
            "number",
          ]);

          if (isValid) {
            updateUser({
              params: { path: { id: userId } },
              body: {
                address: {
                  zipCode: form.getValues("zipCode"),
                  state: form.getValues("state"),
                  city: form.getValues("city"),
                  neighborhoodId: form.getValues("neighborhoodId"),
                  street: form.getValues("street"),
                  number: form.getValues("number"),
                },
              },
            });
          }
        }}
      >
        Salvar
      </Button>
    </>
  );
}
