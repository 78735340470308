import { EyeIcon, Loader2Icon, UploadIcon, XIcon } from "lucide-react";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";

import { Button } from "../ui/button";
import { useSaveImageMutation } from "./hooks/mutations/use-save-image-mutation";
import { usePublicUrlQuery } from "./hooks/queries/use-public-url-query";
import {
  FileType,
  useSignedUrlQuery,
} from "./hooks/queries/use-signed-url-query";
import { createUploadStore } from "./hooks/store/use-upload-store";
import { getFirstFileOrThrow } from "./utils/get-first-file-or-throw";

export type ImageUploadContainerProps = {
  name: string;
  fileType: FileType;

  value: string | null;
  onChange: (value: string | null) => void;
};

export function ImageUploadContainer({
  name,
  fileType,
  value,
  onChange,
}: ImageUploadContainerProps) {
  return (
    <Suspense fallback={<p>Carregando...</p>}>
      <ImageUploadInput
        name={name}
        fileType={fileType}
        value={value}
        onChange={onChange}
      />
    </Suspense>
  );
}

export type ImageUploadInputProps = {
  name: string;
  fileType: FileType;
  value: string | null;
  onChange: (value: string | null) => void;
};

export function ImageUploadInput({
  name,
  fileType,
  value,
  onChange,
}: ImageUploadInputProps) {
  const { data: signedUrl, isLoading, isError } = useSignedUrlQuery(fileType);
  const { data: publicUrl } = usePublicUrlQuery({
    name,
    signedUrl: value,
    fileType,
  });

  if (isLoading) {
    return <p>Carregando...</p>;
  }

  if (isError || !signedUrl) {
    return <p>Ocorreu um erro ao carregar o arquivo</p>;
  }

  return (
    <div className="flex flex-col gap-4">
      <UploadImageHandler
        signedUrl={signedUrl}
        publicUrl={publicUrl}
        fileType={fileType}
        onChange={onChange}
      />
    </div>
  );
}

type UploadImageHandlerProps = {
  signedUrl: string;
  publicUrl: string | null;
  fileType: FileType;
  onChange: (url: string | null) => void;
};

function UploadImageHandler({
  signedUrl,
  publicUrl,
  fileType,
  onChange,
}: UploadImageHandlerProps) {
  const [isUploading, setIsUploading] = useState(false);
  const { mutateAsync: saveImage } = useSaveImageMutation();
  const handleUpload = useCallback(async (receivedFile: File[]) => {
    try {
      const file = getFirstFileOrThrow(receivedFile);

      setIsUploading(true);

      const publicUrl = await saveImage({
        file,
        fileType,
        signedUrl,
      });

      onChange(publicUrl);
    } finally {
      setIsUploading(false);
    }
  }, []);
  const { getInputProps, getRootProps } = useDropzone({
    onDrop: handleUpload,
    multiple: false,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg"],
    },
  });

  return (
    <div>
      <div {...getRootProps()}>
        <div className="mt-4 flex cursor-pointer flex-col items-center gap-2 rounded-lg border bg-muted/40 p-4 transition-all duration-300 hover:bg-muted/20">
          <ImageUploadIcon
            isUploading={isUploading}
            onChange={onChange}
            previewUrl={publicUrl}
          />

          <div className="text-center text-xs text-muted-foreground">
            Até 10 MB
          </div>

          <input {...getInputProps()} />
        </div>
      </div>
    </div>
  );
}

type ImageUploadIconProps = {
  isUploading: boolean;
  previewUrl: string | null;
  onChange: (url: string | null) => void;
};

function ImageUploadIcon({
  isUploading,
  previewUrl,
  onChange,
}: ImageUploadIconProps) {
  if (isUploading) {
    return (
      <div>
        <Loader2Icon className="h-6 w-6 animate-spin" />
      </div>
    );
  }

  if (previewUrl) {
    return (
      <div className="flex flex-col gap-1">
        <div className="group relative">
          <img
            src={previewUrl}
            alt="Previsão da imagem"
            className="w-24 h-24 rounded-lg"
          />

          <div
            className="opacity-0 transition-all duration-200 absolute top-0 h-full w-full flex items-center justify-center group-hover:opacity-100 bg-black/50"
            onClick={(event) => {
              event.stopPropagation();
              window.open(previewUrl, "_blank");
            }}
          >
            <EyeIcon />
          </div>

          <div className="absolute -top-2 -right-2">
            <Button
              type="button"
              size="icon"
              variant="destructive"
              className="rounded-full w-fit h-fit"
              onClick={(event) => {
                event.stopPropagation();
                onChange(null);
              }}
            >
              <XIcon />
            </Button>
          </div>
        </div>

        <p className="text-sm text-muted-foreground">Imagem atual</p>
      </div>
    );
  }

  return (
    <div>
      <UploadIcon />
    </div>
  );
}
