import { z } from "zod";

export const updateUserFormSchema = z.object({
  id: z.string().uuid("Identificador inválido"),
  name: z.string().min(3, "O nome deve ter no mínimo 3 caracteres"),
  email: z.string().email("Insira um endereço de e-mail válido"),
  password: z.string().min(6, "A senha deve ter no mínimo 6 caracteres"),
  cellphone: z
    .string()
    .min(11, "O número de celular deve ter no mínimo 11 caracteres")
    .optional(),
  telephone: z
    .string()
    .min(10, "O número de telefone deve ter no mínimo 10 caracteres")
    .optional(),
  document: z.string().min(11, "O documento deve ter 11 caracteres"),
  companyId: z.string().uuid("Identificador inválido").nullable(),
  roleId: z.string(),
  isBlocked: z.boolean(),
  zipCode: z.string().min(8, "O CEP deve ter no mínimo 8 caracteres"),
  street: z.string().min(3, "A rua deve ter no mínimo 3 caracteres"),
  number: z.string().min(1, "O número deve ser preenchido"),
  neighborhoodId: z.string().uuid(),
  city: z.string().min(3, "A cidade deve ter no mínimo 3 caracteres"),
  state: z.literal("RN", {
    errorMap: () => ({
      message: "No momento, apenas o estado do Rio Grande do Norte é aceito",
    }),
  }),
  avatar: z.string().url().nullable(),
  cnhPicture: z.string().url().nullable(),
  crlvPicture: z.string().url().nullable(),
});

export type UpdateUserSchema = z.infer<typeof updateUserFormSchema>;
