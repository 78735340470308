import { queryClient } from "@/app";

export function invalidateUsersQueries() {
  queryClient.invalidateQueries({
    predicate(query) {
      return (
        query.queryKey.includes("/v1/users") ||
        query.queryKey.includes("/v1/users/{id}")
      );
    },
  });
}
