import { DesktopIcon } from "@radix-ui/react-icons";
import {
  BarChartIcon,
  DollarSignIcon,
  LandmarkIcon,
  LandPlotIcon,
  MoonIcon,
  PackageIcon,
  PieChartIcon,
  SunIcon,
  UsersIcon,
} from "lucide-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandShortcut,
} from "@/components/ui/command";
import { useAuth } from "@/hooks/use-auth";
import { useCommandCenter } from "@/hooks/use-command-center";

import { useTheme } from "./theme-provider";
import { ScrollArea } from "./ui/scroll-area";

export function CommandCenter() {
  const { isOpen, setOpen, toggleOpen } = useCommandCenter();
  const navigate = useNavigate();
  const { setTheme } = useTheme();
  const { isMaster } = useAuth();

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        toggleOpen();
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);

  return (
    <CommandDialog open={isOpen} onOpenChange={setOpen}>
      <CommandInput autoFocus placeholder="Digitar um comando ou buscar..." />
      <CommandList>
        <ScrollArea className="[&>[data-radix-scroll-area-viewport]]:max-h-[300px]">
          <CommandEmpty>Nenhum resultado encontrado.</CommandEmpty>

          <CurrentPageCommands />

          <CommandGroup heading="Páginas">
            <CommandItem
              onSelect={() => {
                navigate("/dashboard/deliveries");
                setOpen(false);
              }}
            >
              <PackageIcon className="mr-2 h-4 w-4" />
              <span>
                Ir para a página de <strong>Entregas</strong>
              </span>
            </CommandItem>

            <CommandItem
              onSelect={() => {
                navigate("/dashboard/reports");
                setOpen(false);
              }}
            >
              <BarChartIcon className="mr-2 h-4 w-4" />
              <span>
                Ir para a página de <strong>Relatórios</strong>
              </span>
            </CommandItem>

            {isMaster() && (
              <CommandItem
                onSelect={() => {
                  navigate("/dashboard/regions");
                  setOpen(false);
                }}
              >
                <LandPlotIcon className="mr-2 h-4 w-4" />
                <span>
                  Ir para a página de <strong>Regiões</strong>
                </span>
              </CommandItem>
            )}

            {isMaster() && (
              <CommandItem
                onSelect={() => {
                  navigate("/dashboard/dynamics");
                  setOpen(false);
                }}
              >
                <DollarSignIcon className="mr-2 h-4 w-4" />
                <span>
                  Ir para a página de <strong>Dinâmicas</strong>
                </span>
              </CommandItem>
            )}

            {isMaster() && (
              <CommandItem
                onSelect={() => {
                  navigate("/dashboard/users");
                  setOpen(false);
                }}
              >
                <UsersIcon className="mr-2 h-4 w-4" />
                <span>
                  Ir para a página de <strong>Usuários</strong>
                </span>
              </CommandItem>
            )}

            {isMaster() && (
              <CommandItem
                onSelect={() => {
                  navigate("/dashboard/companies");
                  setOpen(false);
                }}
              >
                <LandmarkIcon className="mr-2 h-4 w-4" />
                <span>
                  Ir para a página de <strong>Empresas</strong>
                </span>
              </CommandItem>
            )}

            {isMaster() && (
              <CommandItem
                onSelect={() => {
                  navigate("/dashboard/stats");
                  setOpen(false);
                }}
              >
                <PieChartIcon className="mr-2 h-4 w-4" />
                <span>
                  Ir para a página de <strong>Estatísticas</strong>
                </span>
              </CommandItem>
            )}
          </CommandGroup>

          <CommandGroup heading="Tema">
            <CommandItem
              onSelect={() => {
                setTheme("light");
                setOpen(false);
              }}
            >
              <SunIcon className="mr-2 h-4 w-4" />
              <span>
                Escolher tema <strong>claro</strong>
              </span>
            </CommandItem>

            <CommandItem
              onSelect={() => {
                setTheme("dark");
                setOpen(false);
              }}
            >
              <MoonIcon className="mr-2 h-4 w-4" />
              <span>
                Escolher tema <strong>escuro</strong>
              </span>
            </CommandItem>

            <CommandItem
              onSelect={() => {
                setTheme("system");
                setOpen(false);
              }}
            >
              <DesktopIcon className="mr-2 h-4 w-4" />
              <span>
                Escolher tema do <strong>sistema</strong>
              </span>
            </CommandItem>
          </CommandGroup>
        </ScrollArea>
      </CommandList>
    </CommandDialog>
  );
}

function CurrentPageCommands() {
  const { pageCommands, setOpen } = useCommandCenter();

  return (
    <CommandGroup heading="Atalhos da página">
      {pageCommands.map((command) => (
        <CommandItem
          key={command.label}
          onSelect={() => {
            try {
              command.handler();
            } finally {
              setOpen(false);
            }
          }}
          className="flex gap-2 items-center"
        >
          {command.icon}
          <span>{command.label}</span>
          <CommandShortcut>{command.shortcut}</CommandShortcut>
        </CommandItem>
      ))}
    </CommandGroup>
  );
}
