import api from "@/services/api";

import { FileType } from "../hooks/queries/use-signed-url-query";

export async function getPublicUrl({
  signedUrl,
  fileType,
}: {
  signedUrl: string;
  fileType: FileType;
}) {
  /**
   * Deliveryman photos are stored in a public bucket, so we can return the public URL
   * directly
   */
  if (fileType === "deliveryman") {
    const publicAvatarUrl = signedUrl.split("?")[0];

    if (!publicAvatarUrl) {
      throw new Error("Could not get public avatar URL");
    }

    return publicAvatarUrl;
  }

  /**
   * For CNH and CRLV, we need to get a temporary signed URL to access the file
   * from the private bucket
   */
  const temporaryUrl = await getTemporaryUrl({
    fileType,
    fileName: getFileName(signedUrl),
  });

  return temporaryUrl;
}

async function getTemporaryUrl({
  fileName,
  fileType,
}: {
  fileName: string;
  fileType: FileType;
}) {
  const { data } = await api.get<{ url: string }>(
    `/file/${fileType}/view/${fileName}`,
  );

  return data.url;
}

function getFileName(url: string) {
  const fileName = url.split("/").pop();

  if (!fileName) {
    throw new Error("Invalid file name");
  }

  const fileNameWithoutQuery = fileName.split(".")[0];

  if (!fileNameWithoutQuery) {
    throw new Error("Invalid file name");
  }

  return fileNameWithoutQuery;
}
