export function mapApiError(error: { statusCode: number; message: string }) {
  switch (error.statusCode) {
    case 400:
      return {
        title: "Erro de validação",
        description: "Houve um erro de validação dos campos",
      };

    case 409:
      return {
        title: "Endereço de e-mail já cadastrado",
        description: "O usuário já existe na base de dados",
      };

    default:
      return {
        title: "Erro na requisição",
        description: error.message,
      };
  }
}
