import { useSuspenseQuery } from "@tanstack/react-query";

import { getPublicUrl } from "../../utils/get-public-url";
import { FileType } from "./use-signed-url-query";

const USE_PUBLIC_URL_QUERY_KEY = "use-public-url-query";

type GetPublicUrlInput = {
  signedUrl?: string | null;
  fileType: FileType;
};

async function getPublicUrlWrapper({ signedUrl, fileType }: GetPublicUrlInput) {
  if (!signedUrl) {
    return null;
  }

  return getPublicUrl({ signedUrl, fileType });
}

export function usePublicUrlQuery({
  name,
  signedUrl,
  fileType,
}: GetPublicUrlInput & {
  name: string;
}) {
  return useSuspenseQuery({
    queryKey: [USE_PUBLIC_URL_QUERY_KEY, name, fileType, signedUrl],
    queryFn: () => getPublicUrlWrapper({ signedUrl, fileType }),
  });
}
