import { useFormContext } from "react-hook-form";
import { toast } from "sonner";

import {
  PrettyInput,
  PrettyInputItem,
  PrettyInputLabel,
  PrettyInputControl,
  PrettyInputFormMessage,
  PrettyInputFooter,
} from "@/components/pretty-input";
import { Button } from "@/components/ui/button";
import { MaskedInput } from "@/components/ui/masked-input";
import {
  Neighborhood,
  useNeighborhoodsQuery,
} from "@/pages/deliveries/hooks/use-neighborhoods";
import { getAddressByCep } from "@/utils/cep";

import { updateUserMutation } from "../hooks/mutations/update-user-mutation";
import { UpdateUserSchema } from "../schema";

export type ZipCodeAutocompleteProps = {
  userId: string;
};

export function ZipCodeAutocomplete({ userId }: ZipCodeAutocompleteProps) {
  const form = useFormContext<UpdateUserSchema>();
  const { data: neighborhoods } = useNeighborhoodsQuery();
  const { mutate: updateUser } = updateUserMutation();

  return (
    <PrettyInput
      control={form.control}
      name="zipCode"
      render={({ field }) => (
        <PrettyInputItem>
          <PrettyInputLabel>CEP</PrettyInputLabel>

          <PrettyInputControl>
            <MaskedInput
              mask="99999-999"
              {...field}
              onChange={async (event) => {
                const currentValue = event.currentTarget.value;
                const cepDigits = currentValue.replace("-", "");

                field.onChange(currentValue);

                if (cepDigits.length === 8) {
                  const address = await getAddressByCep(cepDigits);

                  if (!isStateAllowed(address.state)) {
                    toast.error("Erro", {
                      description: "No momento, esse estado não é aceito",
                    });
                  }

                  form.setValue("street", address.street);
                  form.setValue("city", address.city);
                  form.setValue("state", address.state as "RN");

                  if (neighborhoods) {
                    const closestNeighborhood = findClosestNeighborhood(
                      neighborhoods.data,
                      address.neighborhood,
                    );

                    form.setValue(
                      "neighborhoodId",
                      closestNeighborhood?.id ?? "",
                    );
                  }
                }
              }}
            />
          </PrettyInputControl>

          <PrettyInputFormMessage />

          <PrettyInputFooter>
            <div>Insira o CEP do endereço do usuário.</div>

            <Button
              type="button"
              size="sm"
              className="w-fit"
              onClick={() =>
                updateUser({
                  params: { path: { id: userId } },
                  body: {
                    address: {
                      zipCode: field.value,
                    },
                  },
                })
              }
            >
              Salvar
            </Button>
          </PrettyInputFooter>
        </PrettyInputItem>
      )}
    />
  );
}

function isStateAllowed(state: string) {
  const allowedStates = ["RN"];

  return allowedStates.includes(state);
}

function findClosestNeighborhood(neighborhoods: Neighborhood[], name: string) {
  return neighborhoods.find((neighborhood) => {
    return neighborhood.name.toLowerCase() === name.toLowerCase();
  });
}
