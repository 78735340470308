import { toast } from "sonner";

import { usersApi } from "@/lib/users-api";

import { invalidateUsersQueries } from "../../utils/invalidate-users-table";
import { mapApiError } from "../../utils/map-api-errors";

export function updateUserMutation() {
  return usersApi.useMutation("patch", "/v1/users/{id}", {
    onSuccess: () => {
      invalidateUsersQueries();

      toast.success("Sucesso", {
        description: "O usuário foi atualizado com sucesso",
      });
    },

    onError: (error) => {
      const { title, description } = mapApiError(error);

      toast.error(title, {
        description,
      });
    },
  });
}
